import { checkForCookieCollision, cookieConsentCountries } from './domainUtils'
import {
  CookieConsentBanner,
  CookieConsentBannerStatuses,
  parseCookieConsentBannerLayout
} from '../banners/bannersSlice'
import apiService from '../../../services/api/apiService'
import { randHex } from '../../../utils/mathUtils'
import { capitalizeString } from '../../../utils/stringUtil'
import { DownloadListParams } from '../../../interfaces'
import { GOOGLE_STORAGE_TYPES } from '../../../constants'
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export enum CookieConsentDomainScanStatuses {
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'inProgress',
  SUCCESS = 'success',
  FAILED = 'failed',
  QUEUED = 'queued'
}

export enum CookieTypes {
  system = 'system',
  manual = 'manual'
}

export type Cookie = {
  id?: string
  name?: string
  value?: string
  domain?: string
  description?: string
  isSecure?: boolean
  expires?: string // in seconds
  path?: string
  size?: string
  httpOnly?: boolean
  secure?: boolean
  session?: boolean
  sameSite?: boolean
  priority?: string
  sameParty?: boolean
  sourcePort?: string
  sourceScheme?: string
  createdBy?: string
  updatedBy?: string
  domainId?: string
  cookieCategoryId?: string
  cookieType?: CookieTypes
  _isFromScan?: boolean
  _isChanged?: boolean
  _isDeleted?: boolean
  details?: {
    Category: string
  }[]
}

export type CookieCategory = {
  createdAt?: string
  id?: string
  name?: string
  description?: string
  createdBy?: string
  updatedBy?: string
  domainId?: string
  cookiesId?: string
  optOut?: boolean
  doNotSell?: boolean
  _isFromScan?: boolean
  _isChanged?: boolean
  _isDeleted?: boolean
  _uiId?: string
}
export type CookieScanHistoryType = {
  scanResults: DomainScanHistory[]
  scannedUrls: string[]
  total: number
}
export type DomainScanHistory = {
  id: string
  jobType: string
  domainId: string
  scanId: string
  jobId: string
  status: CookieConsentDomainScanStatuses
  createdAt: string
  startedAt: number
  completedAt: number
  errorMessage: string
  triggeredBy: string
  scanResult: { cookies: Cookie[] | number }
  isVerified: boolean
}

export type ConsentLog = {
  id: string
  networkIP: string
  updatedAt: string
  location?: string
  consentInfo: { consentAccepted?: string[]; consentRejected?: string[] }
}

export type SubdomainItem = {
  key: 'page' | 'subdomain'
  value: string
}

export type CookieConsentRegionBannerId = {
  bannerId: string
  regionId?: string
  showBanner: boolean
}

export type CookieConsentDomain = {
  id?: string
  domain: string
  subdomains?: string[]
  inclusionList?: SubdomainItem[]
  exclusionList?: SubdomainItem[]
  bannerId?: string
  banner?: CookieConsentBanner
  createdAt?: string
  isScanning?: boolean
  updatedAt?: string
  bannerStatus?: CookieConsentBannerStatuses
  cookiesCount?: number
  categoriesCount?: number
  pagesCount?: number
  jobs?: Array<{
    status: CookieConsentDomainScanStatuses
    createdAt: string
  }>
  emailIds?: string[]
  shareConsent?: boolean
  regionBannerIds?: CookieConsentRegionBannerId[]
  lastScanDate?: string
  googleConsentModeEnabled: boolean
  googleConsentMapper?: ConsentToCategoryMapItem[]
}
export type CookieConsentCount = {
  domainId: string
  cookiesCount: number
  categoriesCount: number
  pagesCount: number
}
export type CookieConsentDomainValidation = {
  validDomains: string[]
  invalidDomains: string[]
  existingDomains: string[]
}

// utils
export const getCookieConsentBannerScriptTag = (props: {
  preferencesOnly: boolean
  scriptUrl: string
  gcmEnabled: boolean
}) => {
  const { scriptUrl = '', preferencesOnly, gcmEnabled } = props
  return `<script src="${scriptUrl}?gcm=${gcmEnabled}&prefCenterOnly=${preferencesOnly}" id="lb-cookie-consent"></script>`
}

// Domain CRUD
export const ACTION_COOKIE_CONSENT_DOMAINS = 'domains/list'
export const fetchCookieConsentDomains = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAINS,
  async () => {
    const domains = await apiService.getCookieConsentDomains()
    const counts = await apiService.getCookieConsentDomainsCounters()

    return domains.map((d) => {
      const countsItem = counts.find((c) => c?.domainId === d.id)
      return { ...d, ...countsItem }
    })
  }
)
export const ACTION_COOKIE_CONSENT_DOMAIN_BY_ID = 'domains/domainById'
export const fetchCookieConsentDomainById = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_BY_ID,
  async (id: string) => {
    const domain = await apiService.getCookieConsentDomainById(id)
    const counts = await apiService.getCookieConsentCountsByDomainId(id)

    return { ...domain, ...counts }
  }
)
export const ACTION_COOKIE_CONSENT_DOMAIN_UPDATE = 'domains/domainUpdate'
export const updateCookieConsentDomain = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_UPDATE,
  async (domain: CookieConsentDomain, { rejectWithValue }) => {
    try {
      await apiService.putCookieConsentDomain(domain)

      if (domain.regionBannerIds?.length) {
        await apiService.putRegionBannerIds({
          domainId: domain?.id || '',
          regionBannerIds: domain?.regionBannerIds || []
        })
      }

      return domain
    } catch (error: any) {
      return rejectWithValue({ statusMessage: error || 'cookieConsent.saveDomain.error' })
    }
  }
)
export const ACTION_COOKIE_CONSENT_DOMAIN_SAVE = 'domains/domainSave'
export const saveCookieConsentDomain = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_SAVE,
  async (domain: CookieConsentDomain, { rejectWithValue }) => {
    try {
      const saveResult = await apiService.postCookieConsentDomain(domain)

      if (domain.regionBannerIds?.length) {
        await apiService.putRegionBannerIds({
          domainId: domain?.id || '',
          regionBannerIds: domain?.regionBannerIds || []
        })
      }
      return saveResult
    } catch (error) {
      return rejectWithValue({ statusMessage: error || 'cookieConsent.saveDomain.error' })
    }
  }
)
export const ACTION_COOKIE_CONSENT_DOMAIN_DELETE = 'domains/delete'
export const deleteCookieConsentDomain = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_DELETE,
  async (id: string) => {
    await apiService.deleteCookieConsentDomainById(id)
    return { id }
  }
)

export type CookieConsentDomainValidationParams = {
  domains?: string[]
}
export const ACTION_COOKIE_CONSENT_DOMAIN_VALIDATE = 'domains/validate'
export const validateCookieConsentDomains = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_VALIDATE,
  async (params: CookieConsentDomainValidationParams) =>
    await apiService.validateCookieConsentDomains(params)
)

export const ACTION_COOKIE_CONSENT_GET_SUBDOMAINS = 'domains/subdomains'
export const fetchSubdomains = createAsyncThunk(
  ACTION_COOKIE_CONSENT_GET_SUBDOMAINS,
  async (domain: string) => await apiService.getSubdomains(domain)
)

export type CookiesExportParams = { apiParams: DownloadListParams; domain: string }

export const ACTION_COOKIE_LIST_EXPORT = 'domains/cookieCsv'
export const exportCookieListCsv = createAsyncThunk(
  ACTION_COOKIE_LIST_EXPORT,
  async (params: CookiesExportParams) => {
    const { data, headers } = await apiService.downloadFile(params.apiParams)
    headers

    const disposition = headers['content-disposition']
    let fileName = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '')
      }
    }

    return { data, fileName }
  }
)

// Cookies CRUD
export interface FetchDomainCookiesParams {
  domainId: string
  page?: number
  pageSize?: number
  filters?: {
    key: string
    values: string[]
  }[]
}
export const ACTION_DOMAIN_COOKIES = 'domains/cookies'
export const fetchDomainCookies = createAsyncThunk(
  ACTION_DOMAIN_COOKIES,
  async (params: FetchDomainCookiesParams) => await apiService.getCookiesByDomainId(params)
)
export const ACTION_DOMAIN_COOKIES_PAGINATED = 'domains/cookies/paginated'
export const fetchDomainCookiesPaginated = createAsyncThunk(
  ACTION_DOMAIN_COOKIES_PAGINATED,
  async (params: FetchDomainCookiesParams) => await apiService.getCookiesByDomainId(params)
)
export const ACTION_DOMAIN_COOKIE_SAVE = 'domains/cookieSave'
export const saveDomainCookies = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_SAVE,
  async (cookies: Cookie[]) => {
    const rawResult = await Promise.allSettled(
      cookies.map((cookie) => {
        delete cookie.id
        return apiService.postDomainCookie(cookie)
      })
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<
      any
    >[]
    return results.map((res) => res.value)
  }
)
export const ACTION_DOMAIN_COOKIE_UPDATE = 'domains/cookieUpdate'
export const updateDomainCookie = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_UPDATE,
  async (cookies: Cookie[]) => {
    const rawResult = await Promise.allSettled(
      cookies.map((cookie) => apiService.putDomainCookie(cookie))
    )
    const results = rawResult.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<
      any
    >[]
    return results.map((res) => res.value)
  }
)
export const ACTION_DOMAIN_COOKIE_DELETE = 'domains/cookieDelete'
export const deleteDomainCookie = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_DELETE,
  async (ids: string[]) => {
    const rawResult = await Promise.allSettled(ids.map((id) => apiService.deleteDomainCookie(id)))
    const results = rawResult.filter((res) => res.status === 'fulfilled') as PromiseFulfilledResult<
      any
    >[]
    return results.map((res) => res.value)
  }
)

// Categories CRUD
export const ACTION_DOMAIN_COOKIE_CATEGORIES = 'domains/cookieCategories'
export const fetchDomainCookieCategories = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_CATEGORIES,
  async (id: string) => await apiService.getCookieCategoriesByDomainId(id)
)
export const ACTION_DOMAIN_COOKIE_CATEGORY_SAVE = 'domains/cookieCategorySave'
export const saveDomainCookieCategory = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_CATEGORY_SAVE,
  async (category: CookieCategory) => {
    delete category.id
    return await apiService.postDomainCookieCategory(category)
  }
)
export const ACTION_DOMAIN_COOKIE_CATEGORY_UPDATE = 'domains/cookieCategoryUpdate'
export const updateDomainCookieCategory = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_CATEGORY_UPDATE,
  async (category: CookieCategory) => await apiService.putDomainCookieCategory(category)
)
export const ACTION_DOMAIN_COOKIE_CATEGORY_DELETE = 'domains/cookieCategoryDelete'
export const deleteDomainCookieCategory = createAsyncThunk(
  ACTION_DOMAIN_COOKIE_CATEGORY_DELETE,
  async (id: string) => {
    await apiService.deleteDomainCookieCategory(id)
    return { id }
  }
)

export interface FetchDomainConsentLogParams {
  page?: number
  pageSize?: number
  domainId: string
}
export const ACTION_DOMAIN_CONSENT_LOG = 'domain/consent-log'
export const fetchDomainConsentLog = createAsyncThunk(
  ACTION_DOMAIN_CONSENT_LOG,
  async (params: FetchDomainConsentLogParams) =>
    await apiService.getCookieConsentLogsByDomainId(params)
)
export const ACTION_DOMAIN_CONSENT_LOG_TOTAL = 'domain/consent-log/total'
export const fetchDomainConsentLogTotal = createAsyncThunk(
  ACTION_DOMAIN_CONSENT_LOG_TOTAL,
  async (params: FetchDomainConsentLogParams) =>
    await apiService.getCookieConsentLogsByDomainId(params)
)
export const ACTION_COOKIE_CONSENT_WEB_APP_URL = 'domains/webAppUrl'
export const fetchCookieConsentWebAppUrl = createAsyncThunk(
  ACTION_COOKIE_CONSENT_WEB_APP_URL,
  async () => await apiService.getWebAppUrl()
)

export interface CookieConsentS3Params {
  domainId: string
  version?: string
}
export const ACTION_COOKIE_CONSENT_S3_BUCKET = 'domains/S3Bucket'
export const fetchCookieConsentS3ScriptUrl = createAsyncThunk(
  ACTION_COOKIE_CONSENT_S3_BUCKET,
  async (params: CookieConsentS3Params) => {
    const result = await apiService.getCookieConsentS3Bucket({ version: params.version })
    return {
      scriptUrl: result.domain_mapping[params.domainId],
      system: result.platform,
      version: result.version
    }
  }
)

export const ACTION_COOKIE_CONSENT_VERSIONS = 'domains/versions'
export const fetchCookieConsentVersions = createAsyncThunk(
  ACTION_COOKIE_CONSENT_VERSIONS,
  async ({ domainId, version }: Partial<CookieConsentS3Params>) => {
    const result = await apiService.getCookieConsentVersions({ domainId, version })
    return {
      versionsList: result.data,
      domainMapping: result.domain_mapping,
      uploadStatusMapping: result.upload_status_mapping,
      versionUpdatedAt: result.updatedAt,
      folderLinks: result.folderLinks
    }
  }
)

// Hosting settings CRUD
export enum CookieConsentHostingSystems {
  lightbeam = 'lightbeam',
  other = 'other'
}
export type CookieConsentHostingSettings = {
  system: CookieConsentHostingSystems
  platform_type: string
  platform_name: string
  isDsOnboarded?: boolean
  datasourceId?: string
  authentication_mechanism: string
  access_key: string
  secret_access_key: string
  aws_bucket_name: string
}

export const ACTION_COOKIE_CONSENT_HOSTING_SETTINGS = 'domains/hostingSettings'
export const fetchCookieConsentHostingSettings = createAsyncThunk(
  ACTION_COOKIE_CONSENT_HOSTING_SETTINGS,
  async () => await apiService.getCookieConsentHostingSettings()
)

export const ACTION_COOKIE_CONSENT_HOSTING_CONFIGURED = 'domains/hostingConfigured'
export const fetchCookieConsentHostingConfigured = createAsyncThunk(
  ACTION_COOKIE_CONSENT_HOSTING_CONFIGURED,
  async () => await apiService.getCookieConsentHostingConfigured()
)

export type UpdateCookieConsentHostingSettingsParams = CookieConsentHostingSettings
export const ACTION_COOKIE_CONSENT_SAVE_HOSTING_SETTINGS = 'domains/saveHostingSettings'
export const saveCookieConsentHostingSettings = createAsyncThunk(
  ACTION_COOKIE_CONSENT_SAVE_HOSTING_SETTINGS,
  async (params: UpdateCookieConsentHostingSettingsParams, { fulfillWithValue }) => {
    const result = await apiService.postCookieConsentHostingSettings(params)
    return fulfillWithValue({ ...result, data: result })
  }
)

// Retry upload files to AWS
export const ACTION_COOKIE_CONSENT_RETRY_UPLOAD = 'domains/retryUpload'
export const cookieConsentRetryUpload = createAsyncThunk(
  ACTION_COOKIE_CONSENT_RETRY_UPLOAD,
  async () => await apiService.getRetryUploadCookieConsentConfig()
)

export type CookieConsentConfigUploadStatus = {
  error_message: string
  upload_status: boolean
  version: string
}
export const ACTION_COOKIE_CONSENT_UPLOAD_STATUS = 'domains/uploadStatus'
export const fetchCookieConsentConfigStatus = createAsyncThunk(
  ACTION_COOKIE_CONSENT_UPLOAD_STATUS,
  async () => await apiService.getCookieConsentConfigStatus()
)

// Increment config version
export const ACTION_COOKIE_CONSENT_CONFIG_INCREMENT_VERSION = 'domains/incrementConfigVersion'
export const cookieConsentIncrementConfigVersion = createAsyncThunk(
  ACTION_COOKIE_CONSENT_CONFIG_INCREMENT_VERSION,
  async () => await apiService.incrementCookieConsentConfigVersion()
)

// Scan domain
export type ScanDomainForCookiesParams = {
  domainId: string
  scanId?: string
}
export const ACTION_COOKIE_CONSENT_DOMAIN_SCAN = 'domains/scan'
export const scanDomainForCookies = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_SCAN,
  async (data: ScanDomainForCookiesParams, { rejectWithValue }) => {
    try {
      const scanResult = await apiService.postScanDomain(data.domainId)
      if (data.scanId) {
        await apiService.approveDomainScanData(data.scanId)
      }
      return { ...data, scanId: scanResult.id }
    } catch (error: any) {
      return rejectWithValue({
        ...data,
        statusMessage: error || 'cookieConsent.domains.step2.scanFailedInfo1'
      })
    }
  }
)
export const ACTION_COOKIE_CONSENT_DOMAIN_SCAN_HISTORY = 'domains/scanHistory'
export const fetchDomainScanHistory = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_SCAN_HISTORY,
  async (domainId: string): Promise<CookieScanHistoryType> => {
    return await apiService.getDomainScanHistory(domainId)
  }
)

export type DomainScanHistoryByScanIdParams = {
  domainId: string
  scanId: string
}
export const ACTION_COOKIE_CONSENT_DOMAIN_SCAN_DATA = 'domains/scanData'
export const fetchDomainScanHistoryByScanId = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DOMAIN_SCAN_DATA,
  async ({ domainId, scanId }: DomainScanHistoryByScanIdParams) =>
    await apiService.getDomainScanDataByScanId(domainId, scanId)
)

export type CookieConsentBulkSaveParams = {
  cookies: Cookie[]
  categories: CookieCategory[]
  scanId?: string
}
export const ACTION_DOMAIN_BULK_COOKIES_SAVE = 'domains/bulkSave'
export const cookieConsentBulkSave = createAsyncThunk(
  ACTION_DOMAIN_BULK_COOKIES_SAVE,
  async (data: CookieConsentBulkSaveParams) => {
    // helper util
    const isSaved = (c) => (c.id || '')?.length > 6
    const isCookieReviewed = (c: Cookie) => !c._isFromScan || (c._isChanged && !c._isDeleted)

    // prepare data
    let cookies = [...data.cookies]
    if (!data.scanId) {
      cookies = data.cookies.filter((c) => isCookieReviewed(c))
    }

    const cookiesByCategory: { [categoryId: string]: Cookie[] } = {}

    cookies.forEach((c) => {
      if (!c.cookieCategoryId) return

      if (!cookiesByCategory[c.cookieCategoryId]) {
        cookiesByCategory[c.cookieCategoryId || ''] = []
      }
      cookiesByCategory[c.cookieCategoryId || ''].push(c)
    })

    // if data.scanId presented, that means it is needed to save both user and scanned cookies
    // otherwise filter only cookies/categories created by user
    let categories = [...data.categories]
    if (!data.scanId) {
      categories = data.categories.filter((c) => {
        const foundReviewedCookie = cookiesByCategory[c.id || '']?.find((c) => isCookieReviewed(c))
        return !c._isFromScan || !!foundReviewedCookie
      })
    }

    // verify scan by id
    if (data.scanId) {
      await apiService.approveDomainScanData(data.scanId)
    }

    // CRUD categories
    const categoriesToPost = categories.filter((c) => !isSaved(c) && !c._isDeleted)
    const categoriesToPut = categories.filter((c) => isSaved(c) && !!c._isChanged && !c._isDeleted)
    const categoriesToDelete = categories.filter((c) => isSaved(c) && c._isDeleted)

    let savedCategories: CookieCategory[] = [...categories]
    if (categoriesToPut.length) {
      await Promise.allSettled(
        categoriesToPut.map(async (category) => {
          const updated = { ...category }
          return await apiService.putDomainCookieCategory(updated)
        })
      )
    }
    if (categoriesToDelete.length) {
      await Promise.allSettled(
        categoriesToDelete.map(
          async (category) => await apiService.deleteDomainCookieCategory(category.id || '')
        )
      )

      savedCategories = savedCategories.filter((c) => !c._isDeleted)
    }
    if (categoriesToPost.length) {
      const saveCategoriesRawResult = await Promise.allSettled(
        categoriesToPost.map(async (category) => {
          const updated = { ...category }
          delete updated.id
          const result = await apiService.postDomainCookieCategory(updated)
          return { ...updated, id: result.id, _uiId: category.id }
        })
      )

      const saveCategoriesResults = saveCategoriesRawResult.filter(
        (res) => res.status === 'fulfilled'
      ) as PromiseFulfilledResult<any>[]
      const savedResults = saveCategoriesResults.map((res) => res.value) || []

      // replace UI IDs with database IDs
      if (savedResults.length) {
        savedCategories = savedCategories.map((c) => {
          const updatedCategory = savedResults?.find((saved) => saved._uiId === c.id) || {}
          return { ...c, ...updatedCategory }
        })
      }
    }

    // CRUD cookies
    // add correct category ID for each cookie
    const preparedCookies = cookies.map((cookie) => {
      const category = savedCategories.find(
        (c) => c.id === cookie.cookieCategoryId || c._uiId === cookie.cookieCategoryId
      )
      return { ...cookie, cookieCategoryId: category?.id || '' }
    })

    const cookiesToPost = preparedCookies
      .filter((c) => !isSaved(c) && !c._isDeleted)
      .map((cookie) => {
        const updated = { ...cookie }
        delete updated.id
        return updated
      })
    const cookiesToPut = preparedCookies.filter(
      (c) => isSaved(c) && !!c._isChanged && !c._isDeleted
    )
    const cookiesToDelete = preparedCookies.filter((c) => isSaved(c) && c._isDeleted)

    if (cookiesToPost.length) {
      await apiService.saveConfirmedDomainCookies({ cookies: cookiesToPost })
    }

    if (cookiesToPut.length || cookiesToDelete.length) {
      await Promise.allSettled(
        cookiesToPut
          .map(async (cookie) => {
            const updated = { ...cookie }
            return await apiService.putDomainCookie(updated)
          })
          .concat(cookiesToDelete.map(async (c) => await apiService.deleteDomainCookie(c.id || '')))
      )
    }

    return true
  }
)
export const ACTION_DOMAIN_SCAN_VERIFY = 'domains/verifyScan'
export const verifyCookieConsentScan = createAsyncThunk(
  ACTION_DOMAIN_SCAN_VERIFY,
  async (scanId: string) => await apiService.approveDomainScanData(scanId)
)

export const ACTION_SAVE_GOOGLE_CONSENT_CATEGORY_MAPPINGS = 'domain/googleConsentCategoryMappings'
export const saveGoogleConsentCategoryMapping = createAsyncThunk(
  ACTION_SAVE_GOOGLE_CONSENT_CATEGORY_MAPPINGS,
  async (consentToCategoryMap) =>
    await apiService.postGoogleConsentCategoryMappings(consentToCategoryMap)
)

const combineScannedDataWithSaved = (payload: {
  cookies?: Cookie[]
  cookiesTotal?: number
  categories?: CookieCategory[]
  categoriesTotal?: number
  scanData?: Cookie[]
  domainId?: string
}) => {
  const updatedCategoriesList: CookieCategory[] = payload.categories || []
  let updatedCategoriesTotal = payload.categoriesTotal || 0

  const updatedCookiesList: Cookie[] = [...(payload.cookies || [])]
  let updatedCookiesTotal = payload.cookiesTotal || 0

  payload.scanData?.forEach((cookie) => {
    // get category name
    const cookieDetails = Array.isArray(cookie.details) ? cookie.details[0] : cookie.details
    const category = capitalizeString(cookieDetails?.Category || 'Others')

    // check category already created/saved by user
    const foundCategory = updatedCategoriesList.find(({ name = '' }) => name === category)

    if (!foundCategory) {
      updatedCategoriesList.push({
        id: randHex(5),
        name: category,
        _isFromScan: true,
        domainId: payload.domainId
      })
      updatedCategoriesTotal += 1
    }

    // don't add cookie if it was already approved
    const approvedCookie = payload.cookies?.find((approved) => {
      return approved.name === cookie.name && approved.domain === cookie.domain
    })
    if (approvedCookie) {
      return
    }
    // add cookie under category with the same name
    updatedCookiesList.push({
      ...cookie,
      id: randHex(6),
      cookieCategoryId: updatedCategoriesList.find((c) => c.name === category)?.id || '',
      _isFromScan: true,
      domainId: payload.domainId,
      cookieType: CookieTypes.system
    })
    updatedCookiesTotal += 1
  })

  return {
    categories: updatedCategoriesList,
    categoriesTotal: updatedCategoriesTotal,
    cookies: updatedCookiesList,
    cookiesTotal: updatedCookiesTotal
  }
}

export type ProblematicCategoryGroup = {
  categoryId: string
  cookies: Cookie[]
}

export type ProblematicDomainGroup = {
  domain: string
  categories: ProblematicCategoryGroup[]
}

// Regions CRUD
export type CookieConsentRegionBannerIdsParams = {
  domainId: string
  regionBannerIds: CookieConsentRegionBannerId[]
}
export enum CookieConsentRegionTypes {
  global = 'Global',
  system = 'System Defined',
  custom = 'Custom'
}

export type CookieConsentRegionCountry = {
  country: string
  states: string[]
  disabled?: boolean
  disabledStates?: string[]
}
export type CookieConsentRegion = {
  id: string
  type: CookieConsentRegionTypes
  name: string
  countriesList: CookieConsentRegionCountry[]
  createdBy?: string
  updatedBy?: string
  createdAt?: string
  updatedAt?: string
  banner?: CookieConsentBanner
  showBanner?: boolean
  _isUpdated?: boolean
  _isNew?: boolean
}

export const mapCookieConsentCountriesListToApi = (countriesList: CookieConsentRegionCountry[]) => {
  return countriesList.map((c) => ({
    country: c.country,
    states: c.states
  }))
}

export type CookieConsentRegionParams = {
  domainId?: string
}
export const ACTION_COOKIE_CONSENT_REGIONS = 'domains/regions'
export const fetchCookieConsentRegions = createAsyncThunk(
  ACTION_COOKIE_CONSENT_REGIONS,
  async (params?: CookieConsentRegionParams) => {
    const result = await apiService.getCookieConsentRegions(params)

    return { regions: result?.regions || [], domainId: params?.domainId }
  }
)

export type UpdateCookieConsentRegionParams = {
  id?: string
  type: string
  name: string
  countriesList: CookieConsentRegionCountry[]
}
export const ACTION_COOKIE_CONSENT_ADD_REGION = 'domains/addRegion'
export const addCookieConsentRegion = createAsyncThunk(
  ACTION_COOKIE_CONSENT_ADD_REGION,
  async (params: CookieConsentRegion) => {
    const payload = {
      type: params.type,
      name: params.name,
      countriesList: mapCookieConsentCountriesListToApi(params.countriesList)
    }
    const { id } = await apiService.postCookieConsentRegion(payload)

    return { ...params, id }
  }
)

export interface CookieConsentS3TestConnectionParams {
  access_key: string
  secret_key: string
  bucket_name: string
}
export const ACTION_COOKIE_CONSENT_TEST_S3_CONNECTION = 'domains/testS3Connection'
export const testCookieConsentS3Connection = createAsyncThunk(
  ACTION_COOKIE_CONSENT_TEST_S3_CONNECTION,
  async (params: CookieConsentS3TestConnectionParams, { fulfillWithValue }) => {
    const result = await apiService.testCookieConsentS3Connection(params)
    return fulfillWithValue({ ...result, data: result })
  }
)

export const ACTION_COOKIE_CONSENT_UPDATE_REGION = 'domains/updateRegion'
export const updateCookieConsentRegions = createAsyncThunk(
  ACTION_COOKIE_CONSENT_UPDATE_REGION,
  async (params: UpdateCookieConsentRegionParams[]) => {
    return await Promise.all(
      params.map(async (region) => {
        await apiService.putCookieConsentRegion({
          ...region,
          countriesList: mapCookieConsentCountriesListToApi(region.countriesList)
        })
      })
    )
  }
)

export const ACTION_COOKIE_CONSENT_DELETE_REGION = 'domains/deleteRegion'
export const deleteCookieConsentRegion = createAsyncThunk(
  ACTION_COOKIE_CONSENT_DELETE_REGION,
  async (id: string) => {
    await apiService.deleteCookieConsentRegion(id)
    return id
  }
)

export const getCookieConsentNewCustomRegion = (
  newRegion?: Partial<CookieConsentRegion>
): CookieConsentRegion => {
  return {
    id: randHex(5),
    type: CookieConsentRegionTypes.custom,
    name: '',
    countriesList: [],
    _isNew: true,
    ...newRegion
  }
}

export const getCookieConsentAvailableCountries = (
  regions: CookieConsentRegion[]
): CookieConsentRegionCountry[] => {
  let regionCountries: CookieConsentRegionCountry[] = []
  const nonGlobalRegions = regions.filter((r) => r.type !== CookieConsentRegionTypes.global)

  nonGlobalRegions.forEach((r) => {
    r.countriesList.forEach((regionCountry) => {
      const foundCountry = regionCountries.find((c) => c.country === regionCountry.country)
      if (foundCountry) {
        regionCountries = regionCountries.map((c) =>
          c.country === regionCountry.country
            ? {
                ...foundCountry,
                states: [...(foundCountry.states || []), ...(regionCountry.states || [])]
              }
            : c
        )
      } else {
        regionCountries.push(regionCountry)
      }
    })
  })

  return cookieConsentCountries.map((c) => {
    const regionCountry = regionCountries.find((taken) => taken.country === c.country)
    const countryWithNoStatesSelected = !!nonGlobalRegions.find((r) =>
      r.countriesList.find((rc) => rc.country === c.country && !rc.states.length)
    )
    const isTaken = regionCountry?.states.length === c.states.length || countryWithNoStatesSelected

    return { ...c, disabled: isTaken, disabledStates: regionCountry?.states || [] }
  })
}

type StringKeyValueMap = {
  [key: string]: string
}
export type DomainMapping = StringKeyValueMap
export type VersionUpdatedAt = StringKeyValueMap
export type FolderLinks = StringKeyValueMap

/**
 * Example of a response
    { // objects inside objects
      "cookie_consent_1": { // version name
        "6d8d9a64-32d9-4fe8-ba60-0c5128a40df6": { // domain ID
          "domain_config_d907f88.json": true,
          "renderCookieConsent_95652cc.js": false,
          "lbstyles_24e91e9.css": false,
          "main_a4b3b33.js": false
        }
      }
    }
 */
export type CookieConsentLogsVersion = {
  [key: string]: boolean
}
export type CookieConsentLogsVersionDomain = {
  [domainId: string]: CookieConsentLogsVersion
}
export type CookieConsentLogsVersionList = {
  [versionName: string]: CookieConsentLogsVersionDomain
}
export type CookieConsentVersionUploadStatus = {
  [versionName: string]: boolean
}

export type ConsentToCategoryMapItem = {
  googleConsentType: GOOGLE_STORAGE_TYPES
  lbCookieCategories: string[]
}

export const CONSENT_TO_CATEGORY_MAP = [
  { googleConsentType: GOOGLE_STORAGE_TYPES.ad_storage, lbCookieCategories: [] },
  { googleConsentType: GOOGLE_STORAGE_TYPES.ad_user_data, lbCookieCategories: [] },
  { googleConsentType: GOOGLE_STORAGE_TYPES.ad_personalization, lbCookieCategories: [] },
  { googleConsentType: GOOGLE_STORAGE_TYPES.analytics_storage, lbCookieCategories: [] },
  { googleConsentType: GOOGLE_STORAGE_TYPES.functionality_storage, lbCookieCategories: [] },
  { googleConsentType: GOOGLE_STORAGE_TYPES.personalization_storage, lbCookieCategories: [] },
  { googleConsentType: GOOGLE_STORAGE_TYPES.security_storage, lbCookieCategories: [] }
]

/** Initial state */
type CookieConsentDomainsState = {
  domains: {
    list: CookieConsentDomain[]
    total: number
  }
  consentLog?: {
    list: ConsentLog[]
    total: number
  }
  scanHistory?: {
    results?: CookieScanHistoryType
    lastScanStatus?: CookieConsentDomainScanStatuses
    lastScanId?: string
    lastJobId?: string
    isVerified?: boolean
    lastScanMessage?: string
  }
  versionsList: CookieConsentLogsVersionList
  domainMapping: DomainMapping
  uploadStatusMapping?: CookieConsentVersionUploadStatus
  versionUpdatedAt: VersionUpdatedAt
  folderLinks: FolderLinks
  config: {
    selectedDomain?: CookieConsentDomain
    detectedSubdomains?: string[]
    approvedCategories?: {
      list?: CookieCategory[]
      total?: number
    }
    approvedCookies?: {
      list?: Cookie[]
      total?: number
    }
    paginatedCookies?: {
      list?: Cookie[]
      total?: number
    }
    mixedCategories?: {
      list?: CookieCategory[]
      total?: number
    }
    mixedCookies?: {
      list?: Cookie[]
      total?: number
    }
    anomalies?: {
      cookieCollision?: boolean
      problematicCategories?: string[]
      problematicDomains?: string[]
      problematicGroupsByDomains?: ProblematicDomainGroup[]
    }
    activeCategoryId?: string
    showErrors: boolean
    webAppUrl?: string
    S3ScriptUrl?: string
    platform?: CookieConsentHostingSystems
    codeVersion?: string
    validSteps: number[]
    domainsValidation: CookieConsentDomainValidation
    isUpdated: boolean
    googleConsentModeEnabled: boolean
    consentToCategoryMap: ConsentToCategoryMapItem[]
  }
  filesToExport?: { data: string; fileName: string }
  location: {
    regions?: CookieConsentRegion[]
    regionsByDomain?: CookieConsentRegion[]
    countries?: CookieConsentRegionCountry[]
  }
  hostingSettings?: CookieConsentHostingSettings
  configUploadStatus: CookieConsentConfigUploadStatus
  isHostingConfigured?: boolean
}

const initialDsrFormsList = {
  list: [],
  total: 0
}

export const initialState: CookieConsentDomainsState = {
  domains: initialDsrFormsList,
  config: {
    showErrors: false,
    validSteps: [2, 5],
    domainsValidation: {
      validDomains: [],
      invalidDomains: [],
      existingDomains: []
    },
    isUpdated: false,
    googleConsentModeEnabled: false,
    consentToCategoryMap: []
  },
  location: {
    countries: cookieConsentCountries
  },
  versionsList: {},
  domainMapping: {},
  versionUpdatedAt: {},
  folderLinks: {},
  configUploadStatus: {
    error_message: '',
    upload_status: true,
    version: ''
  }
}

const domainsSlice = createSlice({
  name: 'domains',
  initialState,
  reducers: {
    resetCookieConsentDomains: (state) => {
      state.domains = initialState.domains
    },
    resetConfig: (state) => {
      state.config = initialState.config
    },
    resetDomainConsentLog: (state) => {
      state.consentLog = initialState.consentLog
    },
    resetDetectedSubdomains: (state) => {
      state.config.detectedSubdomains = initialState.config.detectedSubdomains
    },
    setValidSteps: (state, { payload }) => {
      state.config.validSteps = payload
    },
    setShowConfigErrors: (state, { payload }) => {
      state.config.showErrors = payload
    },
    setSelectedDomain: (state, { payload }) => {
      state.config.selectedDomain = payload
      state.config.isUpdated = true
    },
    setConfigUpdated: (state, { payload }) => {
      state.config.isUpdated = payload
    },
    resetCookieCategories: (state) => {
      state.config.approvedCategories = initialState.config.approvedCategories
      state.config.mixedCategories = initialState.config.mixedCategories
    },
    resetCookies: (state) => {
      state.config.approvedCookies = initialState.config.approvedCookies
      state.config.mixedCookies = initialState.config.mixedCookies
    },
    setActiveCookieCategory: (state, { payload }) => {
      if (state.config.mixedCategories) {
        state.config.activeCategoryId = payload
      }
    },
    resetDomainsValidation: (state) => {
      state.config.domainsValidation = initialState.config.domainsValidation
    },
    resetScanHistory: (state) => {
      state.scanHistory = initialState.scanHistory
    },
    setMixedCategories: (state, { payload }) => {
      state.config.mixedCategories = {
        list: payload,
        total: payload.length
      }
    },
    setGoogleConsentModeEnabled: (state, { payload }) => {
      if (state.config.selectedDomain) {
        state.config.selectedDomain.googleConsentModeEnabled = payload
      }
    },
    setConsentToCategoryMap: (state, { payload }) => {
      if (state.config.selectedDomain) {
        state.config.selectedDomain.googleConsentMapper = payload
      }
    },
    setMixedCookies: (state, { payload }) => {
      state.config.mixedCookies = {
        list: payload,
        total: payload.length
      }
    },
    resetFilesToExport: (state) => {
      state.filesToExport = initialState.filesToExport
    },
    updateRegion: (state, { payload }) => {
      const foundRegion = state.location.regions?.find((r) => r.id === payload.id)

      // update region
      if (foundRegion) {
        state.location.regions = state.location?.regions?.map((region) => {
          return region.id === payload.id ? { ...payload, _isUpdated: true } : region
        })
      } else {
        // add new region that are already saved into DB
        delete payload._isNew
        const updatedRegions = [
          ...(state.location?.regions?.filter((r) => !r._isNew) || []),
          payload
        ]
        state.location.regions = [...updatedRegions, getCookieConsentNewCustomRegion()]
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCookieConsentDomains.fulfilled, (state, { payload }) => {
      state.domains.list = payload.map((d: any) => {
        return { ...d, banner: parseCookieConsentBannerLayout(d.banner) }
      })
      state.domains.total = payload.length || 0
    })
    builder.addCase(fetchCookieConsentDomainById.fulfilled, (state, { payload }) => {
      state.config.selectedDomain = {
        ...payload,
        banner: parseCookieConsentBannerLayout(payload.banner),
        ...(payload.googleConsentMapper
          ? {
              googleConsentMapper: payload.googleConsentMapper.map((item) => ({
                ...item,
                lbCookieCategories: item.lbCookieCategories.map(({ id }) => id)
              }))
            }
          : {})
      }
      state.config.domainsValidation.validDomains = [
        payload.domain,
        ...(payload.inclusionList || [])
      ]
    })
    builder.addCase(saveCookieConsentDomain.fulfilled, (state, { payload }) => {
      if (state.config.selectedDomain) {
        state.config.selectedDomain.id = payload.id
      }
    })
    builder.addCase(updateCookieConsentDomain.fulfilled, (state, { payload }) => {
      state.config.selectedDomain = payload as CookieConsentDomain
    })
    builder.addCase(deleteCookieConsentDomain.fulfilled, (state, { payload }) => {
      state.domains.list = state.domains.list.filter((d) => d.id !== payload.id)
      state.domains.total = state.domains.list.length || 0
    })
    builder.addCase(validateCookieConsentDomains.fulfilled, (state, { payload }) => {
      state.config.domainsValidation = {
        ...payload,
        validDomains: [
          ...new Set([...state.config.domainsValidation.validDomains, payload?.validDomains])
        ]
      }
    })
    builder.addCase(fetchSubdomains.fulfilled, (state, { payload }) => {
      state.config.detectedSubdomains = payload.subdomains || []
    })
    builder.addCase(fetchDomainCookieCategories.fulfilled, (state, { payload }) => {
      state.config.approvedCategories = {
        list: payload.data,
        total: payload.data.length || 0
      }
      state.config.mixedCategories = {
        list: payload.data,
        total: payload.data.length || 0
      }
    })
    builder.addCase(saveDomainCookieCategory.fulfilled, (state, { payload }) => {
      state.config.activeCategoryId = payload.id
    })
    builder.addCase(fetchDomainCookies.fulfilled, (state, { payload }) => {
      if (!(payload.list.length < payload.total)) {
        state.config.approvedCookies = {
          list: payload.list,
          total: payload.total || 0
        }
        state.config.mixedCookies = {
          list: payload.list,
          total: payload.total || 0
        }
        state.config.anomalies = checkForCookieCollision(payload.list)
      }
    })
    builder.addCase(fetchDomainCookiesPaginated.fulfilled, (state, { payload }) => {
      state.config.paginatedCookies = {
        list: payload.list,
        total: payload.total || 0
      }
    })
    builder.addCase(fetchDomainConsentLog.fulfilled, (state, { payload }) => {
      state.consentLog = payload
    })
    builder.addCase(fetchDomainConsentLogTotal.fulfilled, (state, { payload }) => {
      if (state.consentLog) {
        state.consentLog.total = payload.total || 0
      } else {
        state.consentLog = payload
      }
    })
    builder.addCase(fetchCookieConsentWebAppUrl.fulfilled, (state, { payload }) => {
      const result = payload || []
      state.config.webAppUrl = result[0]?.lightbeamWebURL || ''
    })
    builder.addCase(fetchCookieConsentS3ScriptUrl.fulfilled, (state, { payload }) => {
      state.config.S3ScriptUrl = payload.scriptUrl
      state.config.platform = payload.system
      state.config.codeVersion = payload.version
    })
    builder.addCase(fetchCookieConsentVersions.fulfilled, (state, { payload }) => {
      state.versionsList = payload.versionsList
      state.domainMapping = payload.domainMapping
      state.versionUpdatedAt = payload.versionUpdatedAt
      state.folderLinks = payload.folderLinks
      state.uploadStatusMapping = payload.uploadStatusMapping
    })
    // TODO add right types for payload later
    builder.addCase(scanDomainForCookies.fulfilled, (state, { payload }: { payload: any }) => {
      state.scanHistory = {
        results: initialState.scanHistory?.results,
        isVerified: initialState.scanHistory?.isVerified,
        lastScanStatus: CookieConsentDomainScanStatuses.QUEUED,
        lastScanId: payload.scanId
      }
      if (state.config.selectedDomain) {
        state.config.selectedDomain.isScanning = true
      }
      if (state.domains.list) {
        state.domains.list = state.domains.list.map((d) => ({
          ...d,
          isScanning: d.id === payload.domainId
        }))
      }
      if (state.config.mixedCookies?.list) {
        const updatedList = state.config.mixedCookies.list.filter((c) => !c._isFromScan)
        state.config.mixedCookies.list = updatedList
        state.config.mixedCookies.total = updatedList.length
      }
      if (state.config.mixedCategories?.list) {
        const updatedList = state.config.mixedCategories.list.filter((c) => !c._isFromScan)
        state.config.mixedCategories.list = updatedList
        state.config.mixedCategories.total = updatedList.length
      }
    })
    builder.addCase(scanDomainForCookies.rejected, (state, action: AnyAction) => {
      const lastScanMessage = action?.payload?.statusMessage || ''

      state.scanHistory = {
        ...state.scanHistory,
        lastScanStatus: CookieConsentDomainScanStatuses.FAILED,
        lastScanMessage
      }

      if (state.config.selectedDomain) {
        state.config.selectedDomain.isScanning = false
      }
    })
    builder.addCase(fetchDomainScanHistory.fulfilled, (state, { payload }) => {
      /**Select latest scan and set scan status */
      const scanResults = payload['scanResults'] || []
      const lastScan = scanResults[scanResults.length - 1]
      const lastScanId = lastScan?.id
      const lastScanStatus = lastScan?.status
      const isVerified = !!lastScan?.isVerified
      const lastJobId = scanResults?.at(-1)?.jobId ?? ''

      state.scanHistory = { results: payload, lastScanStatus, isVerified, lastScanId, lastJobId }

      if (state.config.selectedDomain) {
        state.config.selectedDomain.isScanning = !!(
          lastScanStatus === CookieConsentDomainScanStatuses.QUEUED ||
          lastScanStatus === CookieConsentDomainScanStatuses.IN_PROGRESS
        )
      }
    })
    builder.addCase(fetchDomainScanHistory.rejected, (state) => {
      state.scanHistory = initialState.scanHistory

      if (state.config.selectedDomain) {
        state.config.selectedDomain.isScanning = false
      }
    })
    builder.addCase(fetchDomainScanHistoryByScanId.fulfilled, (state, { payload }) => {
      const scanResults = payload['scanResults'][0] || []
      const lastScanId = scanResults?.id
      const lastScanStatus = scanResults?.status
      const isVerified = !!scanResults?.isVerified
      const combined = combineScannedDataWithSaved({
        domainId: state.config.selectedDomain?.id || '',
        categories: [...(state.config.mixedCategories?.list || [])],
        categoriesTotal: state.config.mixedCategories?.total || 0,
        cookies: [...(state.config.mixedCookies?.list || [])],
        cookiesTotal: state.config.mixedCookies?.total || 0,
        scanData: [
          ...(scanResults?.scanResult?.cookies || []).filter((c) => (c.id || '').length < 8)
        ]
      })

      state.config.mixedCategories = {
        list: combined.categories,
        total: combined.categoriesTotal
      }
      state.config.mixedCookies = {
        list: combined.cookies,
        total: combined.cookiesTotal
      }
      const isScanning =
        lastScanStatus === CookieConsentDomainScanStatuses.QUEUED ||
        lastScanStatus === CookieConsentDomainScanStatuses.IN_PROGRESS
      if (state.config.selectedDomain) {
        state.config.selectedDomain.isScanning = isScanning
      }
      if (state.scanHistory) {
        state.scanHistory.lastScanStatus = lastScanStatus
        state.scanHistory.isVerified = isVerified
        state.scanHistory.lastScanId = lastScanId
        const jobId = payload['scanResults']?.at(-1)?.jobId
        state.scanHistory.lastJobId = jobId ?? ''

        const scanResultsFiltered =
          state.scanHistory?.results?.scanResults?.filter((job) => job.jobId !== jobId) || []

        state.scanHistory.results = {
          ...state.scanHistory.results,
          scanResults: [
            ...scanResultsFiltered,
            {
              ...scanResults,
              scanResult: {
                cookies: scanResults?.scanResult?.cookies?.length || 0,
                totalPagesScanned: scanResults?.scanResult?.scannedUrls?.length || 0
              }
            }
          ],
          scannedUrls: state.scanHistory.results?.scannedUrls || [],
          total: (state.scanHistory.results?.total || 0) + 1
        }
      }
      if (state.domains.list) {
        state.domains.list = state.domains.list.map((d) => {
          return d.id === scanResults.domainId ? { ...d, isScanning } : d
        })
      }
    })
    builder.addCase(cookieConsentBulkSave.fulfilled, (state) => {
      if (state.scanHistory) {
        state.scanHistory.isVerified = true
      }
      state.config.approvedCookies = initialState.config.approvedCookies
      state.config.approvedCategories = initialState.config.approvedCategories
      state.config.mixedCategories = initialState.config.mixedCategories
      state.config.mixedCookies = initialState.config.mixedCookies
    })
    builder.addCase(exportCookieListCsv.fulfilled, (state, { payload }) => {
      state.filesToExport = payload
    })
    builder.addCase(fetchCookieConsentRegions.fulfilled, (state, { payload }) => {
      if (payload.domainId) {
        state.location.regionsByDomain = payload.regions
      } else {
        state.location.regions = [...payload.regions, getCookieConsentNewCustomRegion()]
      }
    })
    builder.addCase(fetchCookieConsentHostingSettings.fulfilled, (state, { payload }) => {
      state.hostingSettings = payload.cookieConsentConfig
    })
    builder.addCase(fetchCookieConsentHostingConfigured.fulfilled, (state, { payload }) => {
      state.isHostingConfigured = !!payload.success
    })
    builder.addCase(fetchCookieConsentConfigStatus.fulfilled, (state, { payload }) => {
      state.configUploadStatus = payload
    })
    builder.addCase(fetchCookieConsentConfigStatus.rejected, (state) => {
      state.configUploadStatus = {
        upload_status: false,
        error_message: '',
        version: ''
      }
    })
  }
})

export const {
  resetCookieConsentDomains,
  resetConfig,
  resetDetectedSubdomains,
  setValidSteps,
  setShowConfigErrors,
  setSelectedDomain,
  setConfigUpdated,
  resetCookieCategories,
  resetCookies,
  setActiveCookieCategory,
  resetDomainConsentLog,
  resetDomainsValidation,
  resetScanHistory,
  setMixedCookies,
  setMixedCategories,
  setGoogleConsentModeEnabled,
  setConsentToCategoryMap,
  resetFilesToExport,
  updateRegion
} = domainsSlice.actions

export default domainsSlice.reducer
